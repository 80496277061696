import * as React from 'react'
import Layout from '/src/components/layout'

const NotFoundPage = () => {
	return (
		<Layout pageTitle="404 Not found">

			<p>Sidan du försöker nå finns inte eller är tillfälligt otillgänglig.</p>
			
		</Layout>
	)
}

export default NotFoundPage